import Web3 from 'web3';
import axios from "axios";
import mintAndList from 'contracts/PerseaSimpleCollection.json';
import mint from 'contracts/PerseaSimpleCollectionBBVA.json';
import mintAndReveal from 'contracts/PerseaSimpleCollectionPL.json';
import gaslessMintAndReveal from 'contracts/PerseaSimpleCollectionGasless.json';
import mintSeq from 'contracts/PerseaSimpleCollectionSeq.json';
import mintWeeksGasless from 'contracts/PerseaSimpleCollectionWeeksGasless.json';
import ERC721WithWhitelist from 'contracts/PerseaSimpleCollectionWhiteList.json';
import PerseaSimpleCollectionSeqPricePayees from 'contracts/PerseaSimpleCollectionSeqPricePayees.json';
import { validateDomain } from './app';
axios.defaults.headers.common['X-API-Key'] =process.env.REACT_APP_X_API_KEY

export const MINT_TYPES = {
    mintWeeksGasless: 6,
    mintERC721WithWhitelist: 7,
    mintWithPriceAndPayees: 8,
};

const abis = {
    1 : mintAndList,
    2: mint,
    3: mintAndReveal,
    4: gaslessMintAndReveal,
    5: mintSeq,
    [MINT_TYPES.mintWeeksGasless]: mintWeeksGasless,
    [MINT_TYPES.mintERC721WithWhitelist]: ERC721WithWhitelist,
    [MINT_TYPES.mintWithPriceAndPayees]: PerseaSimpleCollectionSeqPricePayees,
    10 : {
        abi : [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "trustedForwarder",
                        "type": "address"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "constructor"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "approved",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "Approval",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "operator",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "bool",
                        "name": "approved",
                        "type": "bool"
                    }
                ],
                "name": "ApprovalForAll",
                "type": "event"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "approve",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bytes",
                        "name": "signature",
                        "type": "bytes"
                    }
                ],
                "name": "mintNFT",
                "outputs": [],
                "stateMutability": "payable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "from",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "safeTransferFrom",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "from",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bytes",
                        "name": "data",
                        "type": "bytes"
                    }
                ],
                "name": "safeTransferFrom",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "operator",
                        "type": "address"
                    },
                    {
                        "internalType": "bool",
                        "name": "approved",
                        "type": "bool"
                    }
                ],
                "name": "setApprovalForAll",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "from",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "Transfer",
                "type": "event"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "from",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "transferFrom",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "getApproved",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "operator",
                        "type": "address"
                    }
                ],
                "name": "isApprovedForAll",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "forwarder",
                        "type": "address"
                    }
                ],
                "name": "isTrustedForwarder",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "mintCost",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "name",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "ownerOf",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "bytes4",
                        "name": "interfaceId",
                        "type": "bytes4"
                    }
                ],
                "name": "supportsInterface",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "symbol",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "tokenURI",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }
        ]
    }
}

const addressUSDC = {
    137 : "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    80001 : "0x0FA8781a83E46826621b3BC094Ea2A0212e71B23"
}

const url = process.env.REACT_APP_API+"blockchain"

const format = (blockchain) => {
    return {
        name: blockchain.name,
        rpc: blockchain.rpc,
        icon : blockchain.icon,
        symbol : blockchain.symbol,
        explorer : blockchain.explorer,
        chainid : blockchain.chainid,
        alternative_rpc : blockchain.alternative_rpc
    }
}
export const findAllByDomain = async (domain,onlyTestnets = true) => {
    if(!validateDomain(domain)) {
        throw new Error('Invalid app')
    }
    const result = await axios.get(url+"?domain="+domain);
    let blockchains = []
    for (const blockchain of result.data) {
        if(onlyTestnets) {
            if(blockchain.testnet) {
                blockchains.push(format(blockchain))
            }
        }  else {
            blockchains.push(format(blockchain))
        }
    }
    return blockchains;
}


export const findFirst = async (domain,onlyTestnets = true) => {
    const allBlockchains = await findAllByDomain(domain, onlyTestnets);
    return allBlockchains[0];
}

export const findAllByName = async(domain,name) => {
    const blockchains = await findAllByDomain(domain)
    let newBlockchain = null;
    blockchains.forEach(blockchain => {
        newBlockchain = blockchain
        if(blockchain.name.toUpperCase().includes(name.toUpperCase().replace('%20',' '))) {
            newBlockchain = blockchain
        }
    })
    if(!newBlockchain) {
        throw new Error('Invalid blockchain for mint');
    }
    return newBlockchain
}

export const findABIOf = (contractId) => {
    return abis[contractId].abi
}

export const callSign = async(from, provider) => {
    let web3 = new Web3(provider);
    const to = from; // The address to receive the minted NFT
    const contractAddress = "0x01e4eca93410b485165dcfb7c95cd1bd7d3e79bc";
    const tokenId = 1; // The ID of the NFT token to mint
    const mintCost = web3.utils.toWei('1', 'ether'); // The cost of minting in Ether
    const myNFTContract = new web3.eth.Contract(abis[10].abi, contractAddress);
    const data = myNFTContract.methods.mintNFT(to, tokenId,'').encodeABI();
    const nonce = await web3.eth.getTransactionCount(from, 'latest');
    const gasPrice = await web3.eth.getGasPrice();
    const gasLimit = 300000;
    const tx = {
      from: from,
      to: contractAddress,
      nonce: web3.utils.toHex(nonce),
      gasPrice: web3.utils.toHex(gasPrice),
      gasLimit: web3.utils.toHex(gasLimit),
      data: data,
      value: mintCost
    };
    const signedTx = await provider.request({
        method: "eth_signTransaction",
        params: [tx],
    });
    console.log(signedTx)
}


export const sign = async (from, provider, text = "CREATE_COLLECTION") => {
    let web3 = new Web3(provider);
    let message = web3.utils.sha3(text + ' ' + new Date().getTime());
    let hex = '';
    for (let i = 0; i < message.length; i++) {
        hex += '' + message.charCodeAt(i).toString(16);
    }
    const hexMessage = '0x' + hex;
    let signature = await web3.eth.personal.sign(hexMessage, from);
    localStorage.setItem('signature', signature);
    localStorage.setItem('message', message);
    return { signature, message };
};

export const deployERC721WithWhiteList = async (from, provider, name, symbol, version, manager, limit, folder, whitelistAddresses, whitelistAmounts) => {
    const web3 = new Web3(provider);
    const abi = abis[7];
    const contract = new web3.eth.Contract(abi.abi)
    const chainId = await web3.eth.getChainId()
    const send = await getGasConfigurationFor(chainId)
    send['from'] = web3.utils.toChecksumAddress(from)
    return  contract.deploy({
        data: abi.bytecode,
        arguments: [
            name, symbol, version, manager, whitelistAddresses, whitelistAmounts, {folderURI: folder, limit: limit}
        ]
        }).send(send)
}

export const deploy = async(
    folder,
    price,limit,from,provider,
    contractId = 1,
    name = "",
    symbol = "",
    payees = [],
    shares = [],
    lastPrice = 0,
    count = 0,
    priceUSDC = 0,
    version="",
    contractURI="/",
    reservedNFTS=[],
    claimants = [],
    claimantsConfigs = [],
    gateway = "",
) => {
    let web3 = new Web3(provider);
    let contract = new web3.eth.Contract(abis[contractId].abi)
    if(contractId === 1) {
        price = web3.utils.toWei(""+price);
    }
    if(contractId === 3) {
        price = web3.utils.toWei(""+price);
        let configuration = {
            price : price,
            folderURI : folder,
            limit : limit
        }
        console.log(' name,symbol,configuration,payees,shares',  name,symbol,configuration,payees,shares)
        return  contract.deploy({
            data :abis[contractId].bytecode,
            arguments : [
             name,symbol,configuration,payees,shares
            ]
         }).send({
           from : web3.utils.toChecksumAddress(from)
         })
    }

    if(contractId === 4) {
        let chainId = await web3.eth.getChainId()
        price = web3.utils.toWei(""+price);
        let listTokenIds = [];
        for (let index = 1; index <= limit; index++) {
            listTokenIds.push(1)
        }
        console.log('chainId',chainId)
        let configuration = {
            price : price,
            folderURI : folder,
            ERC20Price : parseFloat(""+priceUSDC) * 1000000,
            ERC20ContractAddress : addressUSDC[chainId],
            limit : limit
        }
        console.log(' name,symbol,configuration,payees,shares',  name,symbol,configuration,payees,shares)
        return  contract.deploy({
            data :abis[contractId].bytecode,
            arguments : [
             name,symbol,configuration,payees,shares
            ]
         }).send({
           from : web3.utils.toChecksumAddress(from)
         })
    }
    if(contractId === 5) {
        let chainId = await web3.eth.getChainId()
        price = web3.utils.toWei(""+price);
        lastPrice = web3.utils.toWei(""+lastPrice);
        let configuration = {
            folderURI : folder,
            limit : limit
        }
        let send = await getGasConfigurationFor(chainId)
        send['from'] = web3.utils.toChecksumAddress(from)
        console.log('send ::',send)
        //send['maxPriorityFeePerGas'] = web3.utils.toWei(""+(send.maxPriorityFee.toFixed(2)),'gwei')
        return  contract.deploy({
            data :abis[contractId].bytecode,
            arguments : [
             name,symbol,configuration,payees, shares,count,0,price,lastPrice
            ]
         }).send(send)
    }
    console.log("Contract id: ", contractId);
    if(Number(contractId) === MINT_TYPES.mintWeeksGasless) {
        let chainId = await web3.eth.getChainId()
        let send = await getGasConfigurationFor(chainId)
        send['from'] = web3.utils.toChecksumAddress(from)
        return  contract.deploy({
            data :abis[contractId].bytecode,
            arguments : [
             name, symbol, version, folder, contractURI, limit, reservedNFTS, claimants, claimantsConfigs
            ]
         }).send(send)
    }
    if(Number(contractId) === MINT_TYPES.mintWithPriceAndPayees) {
        //const chainId = await web3.eth.getChainId()
        console.log('contract params ', name, symbol, gateway, contractURI, price, payees, shares)
        let send =  {}
        send['from'] = web3.utils.toChecksumAddress(from)
        price = web3.utils.toWei(""+price);
        return  contract.deploy({
            data :abis[contractId].bytecode,
            arguments : [
                name, symbol, gateway, contractURI, price, payees, shares
            ]
        }).send(send)
    }
    return  contract.deploy({
        data :abis[contractId].bytecode,
        arguments : [
          folder,price,limit
        ]
     }).send({
       from : web3.utils.toChecksumAddress(from)
     })
}

export const contractData = (str)  => {
    let words = str.split(' ');
    console.log('words ::', words);
    let symbol = '';
    let name = '';
    for(let word of words) {
        symbol += word['0'];
    }
    name = symbol;
    return { name, symbol };
}


export const getGasConfigurationFor = async(chainId) => {
    let data = {};
    console.log('chainId 2',chainId)
    if(chainId === 80001) {
        let gas = await getGasConfigurationMumbai();
        console.log('gas ::',gas)
        data = gas.fast
    }
    return data;
}

export const getGasConfigurationMumbai = async() => {
    return await axios.get('https://gasstation-mumbai.matic.today/v2').then((response) => response.data)
}

export const requestAddNetwork = (blockchain) => {
    console.log('blockchain',blockchain)
    if (window.ethereum && window.ethereum.isConnected()) {
        window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: blockchain.chainid,
              chainName: blockchain.name,
              nativeCurrency: {
                name: blockchain.name,
                symbol: blockchain.symbol,
                decimals: 18
              },
              rpcUrls: [blockchain.rpc],
              blockExplorerUrls: [blockchain.explorer],
            }],
          })
          .then((response) =>{
            console.log(response);
          })
          .catch((error) =>{
            console.log(error);
          });
    }
}

export const requestChangeNetwork = (blockchain) => {
    console.log('blockchain',blockchain)
    if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        web3.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: blockchain.chainid }] // Replace with the desired chain ID
        })
        .then(() => console.log('Network changed successfully!'))
        .catch((error) => console.log(error));
    }
}

export const isAddress = (address) => {
    const web3 = new Web3();
    return web3.utils.isAddress(address)
}

export  const checkSumAddress = (address) => {
    const web3 = new Web3();
    return web3.utils.toChecksumAddress(address)
}